import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import * as yup from "yup";
import { FormikProvider, useFormik } from "formik";

import { Button, Modal, Typography } from "design_system/src";

import { phoneRegex } from "models/constants/regex.constants";
import { formatPhone } from "helpers/phone";
import { store } from "store";

import { ValidationIdentityFormEnum } from "enums/validationIdentityForm.enum";
import { ValidateIdentityStepperFormValues, SUPPORTED_FORMATS, DOCUMENTSTOUPDATE, fetchImageAsBlob } from "views/ozonFly/ozonFlyForm/OzonFlyForm";
import { transformFinancialFormObj, useFinancialForm } from "hooks/useGetFinancialForm";
import { fetchUpdateFinancialForm } from "helpers/fetchMiCuenta";
import { FinancialFormStatus } from "models/financialForm.interface";
import { IDocumentsOzoner } from "views/ozonFly/hooks/useUploadFiles";
import _ from "lodash";
import { relationOptionsValues } from "views/ozonFly/steps";

import { StepperItemProps, StepperItem } from "../components/stepperItem/StepperItem";
import { AddressStep, IncomesStep, IneStep, ReferencesStep, SelfieStep } from "./steps";


type StepType = ValidationIdentityFormEnum.INDENTITY_STEP |
    ValidationIdentityFormEnum.SELFIE_STEP |
    ValidationIdentityFormEnum.ADDRESS_STEP |
    ValidationIdentityFormEnum.CERTIFICATES_STEP |
    ValidationIdentityFormEnum.REFERENCES_STEP;

interface ReferencesFormValues {
    [ValidationIdentityFormEnum.REFERENCES_STEP]: {
        personal_reference_1: {
            name: string;
            phone: string;
            relation: string;
            other: string;
        };
        personal_reference_2: {
            name: string;
            phone: string;
            relation: string;
            other: string;
        };
    };
}

const documentSchema = (): StepperItemProps[] => [
    {
        step: ValidationIdentityFormEnum.INDENTITY_STEP,
        title: "INE legible por ambas caras",
        completed: false,
        active: false,
        enabled: true,
        documentsRequired: ["ine_front", "ine_back"],
        documents: [],
        component: <IneStep />
    },
    {
        step: ValidationIdentityFormEnum.SELFIE_STEP,
        title: "Selfie",
        completed: false,
        active: false,
        enabled: true,
        documentsRequired: ["selfie"],
        documents: [],
        component: <SelfieStep />
    },
    {
        step: ValidationIdentityFormEnum.ADDRESS_STEP,
        title: "Comprobante de domicilio",
        completed: false,
        active: false,
        enabled: true,
        documentsRequired: ["proof_of_address"],
        documents: [],
        component: <AddressStep />
    },
    {
        step: ValidationIdentityFormEnum.CERTIFICATES_STEP,
        title: "Comprobante de ingresos",
        completed: false,
        active: false,
        enabled: true,
        documentsRequired: ["bank_certificate_1", "bank_certificate_2", "bank_certificate_3"],
        documents: [],
        component: <IncomesStep />
    },
    {
        step: ValidationIdentityFormEnum.REFERENCES_STEP,
        title: "Referencias",
        completed: false,
        active: false,
        enabled: true,
        component: <ReferencesStep />
    },
];

const validationSchema = (workInDigitalPlatforms: any) => yup.object().shape({
    [ValidationIdentityFormEnum.INDENTITY_STEP]: yup.object().shape({
        ine_front: yup
            .mixed()
            .required("Campo requerido")
            .test("fileSize", "El archivo es demasiado grande", (data) => {
                if (data?._id) {
                    return true;
                }
                if (!data || !data.imageFile) {
                    return false;
                }
            return data.imageFile.size <= 1000000 * 5;
            })
            .test(
                "format",
                "El archivo no corresponde al formato jpg/jpeg o png",
                (value) => {
                    if (value?._id) {
                        return true;
                    }
                    if (!value || !value.imageFile || !value.imageFile.type) {
                        return false;
                    }
                    return SUPPORTED_FORMATS.includes(value.imageFile.type);
                }
            ),
        ine_back: yup
            .mixed()
            .required("Campo requerido")
            .test("fileSize", "El archivo es demasiado grande", (data) => {
                if (data?._id) {
                    return true;
                }
                if (!data || !data.imageFile) {
                    return false;
                }
                return data.imageFile.size <= 1000000 * 5;
            })
            .test(
                "format",
                "El archivo no corresponde al formato jpg/jpeg o png",
                (value) => {
                    if (value?._id) {
                    return true;
                    }
                    if (!value || !value.imageFile || !value.imageFile.type) {
                    return false;
                    }
                    return SUPPORTED_FORMATS.includes(value.imageFile.type);
                }
            ),
    }),
    [ValidationIdentityFormEnum.SELFIE_STEP]: yup.object().shape({
        selfie: yup
            .mixed()
            .required("Campo requerido")
            .test("fileSize", "El archivo es demasiado grande", (data) => {
                if (data?._id) {
                    return true;
                }
                if (!data || !data.imageFile) {
                    return false;
                }
                return data.imageFile.size <= 1000000 * 5;
            })
            .test(
            "format",
            "El archivo no corresponde al formato jpg/jpeg o png",
            (value) => {
                if (value?._id) {
                    return true;
                }
                if (!value || !value.imageFile || !value.imageFile.type) {
                    return false;
                }
                return SUPPORTED_FORMATS.includes(value.imageFile.type);
            }
            ),
    }),
    [ValidationIdentityFormEnum.ADDRESS_STEP]: yup.object().shape({
        proof_of_address: yup.mixed().required("Campo requerido"),
    }),
    [ValidationIdentityFormEnum.CERTIFICATES_STEP]: yup.object().shape({
        bank_certificate_1: yup.mixed().when([], {
            is: () => !workInDigitalPlatforms,
            then: yup.mixed().required("Campo requerido"),
            otherwise: yup.mixed(),
        }),
        bank_certificate_2: yup.mixed().when([], {
            is: () => !workInDigitalPlatforms,
            then: yup.mixed().required("Campo requerido"),
            otherwise: yup.mixed(),
        }),
        bank_certificate_3: yup.mixed().when([], {
            is: () => !workInDigitalPlatforms,
            then: yup.mixed().required("Campo requerido"),
            otherwise: yup.mixed(),
        }),
    }),
    [ValidationIdentityFormEnum.REFERENCES_STEP]: yup.object().shape({
        personal_reference_1: yup.object().shape({
            name: yup.string().required("Campo requerido"),
            phone: yup
                .string()
                .matches(phoneRegex, "Número inválido")
                .required("Campo requerido"),
            relation: yup.string().required("Campo requerido"),
            other: yup
                .string()
                .test(
                    "",
                    "Campo requerido",
                    function (value) {
                        if (!value && this.parent.relation === relationOptionsValues.OTHER) {
                            return false;
                        }
                        return true;
                    }
                ),
        }),
        personal_reference_2: yup.object().shape({
            name: yup.string().required("Campo requerido"),
            phone: yup
                .string()
                .matches(phoneRegex, "Número inválido")
                .required("Campo requerido"),
            relation: yup.string().required("Campo requerido"),
            other: yup
                .string()
                .test(
                    "",
                    "Campo requerido",
                    function (value) {
                        if (!value && this.parent.relation === relationOptionsValues.OTHER) {
                            return false;
                        }
                        return true;
                    }
                ),
        })
    }),
});

export const ApplicationDocumentsScreen = () => {
    const navigate = useNavigate();
    const { user } = store.getState().userReducer;
    const matchesMD = useMediaQuery("(min-width:924px)");
    const matchesXS = useMediaQuery("(min-width:600px)");
    const [openDetail, setOpenDetail] = useState<boolean>(false);
    const [activeSection, setActiveSection] = useState<StepType | null>(null);

    const [documentSection, setDocumentSection] = useState<StepperItemProps[]>(() => documentSchema());
    const [completed, setCompleted] = useState(false);
    const [stepBody, setStepBody] = useState<any>();
    const [financialForm, setFinancialForm] = useFinancialForm();

    const handleActiveSection = (value: StepType | null) => {
        setActiveSection(value);
    };

    useEffect(() => {
        setOpenDetail(matchesMD);
        if (matchesMD) {
            handleActiveSection(ValidationIdentityFormEnum.INDENTITY_STEP);
        }
    }, [matchesMD]);

    useEffect(() => {
        setDocumentSection(documentSection.map((e) => {
            e.active = e.step === activeSection;
            return e;
        }));
        setStepBody(documentSection
            .filter(({step}) => step === activeSection)
            .map(({component}) => component)[0]
        );

        if (!matchesMD) {
            setOpenDetail(activeSection !== null);
        }
    }, [activeSection]);

    const form = useFormik<ValidateIdentityStepperFormValues & ReferencesFormValues>({
        initialValues: {
            [ValidationIdentityFormEnum.INDENTITY_STEP]: {
                ine_front: null,
                ine_back: null,
            },
            [ValidationIdentityFormEnum.SELFIE_STEP]: {
                selfie: null,
            },
            [ValidationIdentityFormEnum.ADDRESS_STEP]: {
                proof_of_address: null,
            },
            [ValidationIdentityFormEnum.CERTIFICATES_STEP]: {
                bank_certificate_1: null,
                bank_certificate_2: null,
                bank_certificate_3: null,
            },
            [ValidationIdentityFormEnum.REFERENCES_STEP]: {
                personal_reference_1: {
                    name: "",
                    phone: "",
                    relation: "",
                    other: "",
                },
                personal_reference_2: {
                    name: "",
                    phone: "",
                    relation: "",
                    other: "",
                },
            }
        },
        validationSchema: () => validationSchema(financialForm?.workInDigitalPlatforms),
        onSubmit: () => {},
    });

    useEffect(() => {
        form.setFormikState((prevState) => ({
            ...prevState,
            validationSchema: validationSchema(financialForm?.workInDigitalPlatforms),
        }));
    }, [financialForm?.workInDigitalPlatforms]);

    useEffect(() => {
        const refErrors = _.get(form.errors, ValidationIdentityFormEnum.REFERENCES_STEP);
        setDocumentSection(documentSection.map((e) => {
            if (e.step === ValidationIdentityFormEnum.REFERENCES_STEP) {
                e.completed = refErrors === undefined;
            }
            return e;
        }));
    }, [form.errors]);

    useEffect(() => {
        if (financialForm) {
            const { documents, personal_references : personalReferences, status } = financialForm;
            if (status !== FinancialFormStatus.PENDING) {
                navigate("/mi-cuenta");
            }
            documents.forEach((element: IDocumentsOzoner) => {
                const documentToUpdate = DOCUMENTSTOUPDATE[element.name as keyof typeof DOCUMENTSTOUPDATE];
                if (documentToUpdate) {
                    if (documentToUpdate && form.values[documentToUpdate as keyof typeof form.values] !== null) {
                        fetchImageAsBlob(element.url, element.name).then((imageFile) => {
                            form.setFieldValue(documentToUpdate, imageFile, true);
                        });

                        setDocumentSection(documentSection.map((e) => {
                            if (e.documentsRequired && e.documents) {
                                if (e.documentsRequired?.includes(element.name) && !e.documents?.includes(element.name)) {
                                    e.documents?.push(element.name);
                                }
                                e.completed = e.documentsRequired?.length === e.documents?.length;
                            }
                            return e;
                        }));
                    }
                }
            });

            if (personalReferences) {
                form.setFieldValue(ValidationIdentityFormEnum.REFERENCES_STEP, personalReferences);
            }

            Object.keys(DOCUMENTSTOUPDATE).filter((e) => !documents.map((i) => i.name).includes(e)).forEach((doc) => {
                const documentToUpdate = DOCUMENTSTOUPDATE[doc as keyof typeof DOCUMENTSTOUPDATE];
                form.setFieldValue(documentToUpdate, null, true);
            });
        }
    }, [financialForm, ]);

    useEffect(() => {
        setDocumentSection(documentSection.map((e) => {
            if (e.step === ValidationIdentityFormEnum.CERTIFICATES_STEP) {
                e.component= <IncomesStep workDigitalPlatforms={financialForm?.workInDigitalPlatforms}/>;
            }
            return e;
        }));
    }, [financialForm]);

    useEffect(() => {
        setFinancialForm(transformFinancialFormObj(user));
    }, [user]);

    useEffect(() => {
        if (completed) {
            ReactGA.event("VIEW_MyAccount_documents_completed", {
                category: "VIEW_MyAccount_documents_completed",
                label: "page view with document completed message",
            });
        }
    }, [completed]);

    const handleBackButton = () => {
        window.scrollTo(0, 0);
        setActiveSection(null);
    };

    const updatePersonalReferences = async () => {
        const { values } = form;
        if (financialForm) {
            const referencesData = {
                personal_references: values[ValidationIdentityFormEnum.REFERENCES_STEP]
            };

            referencesData.personal_references.personal_reference_1.phone = formatPhone(
                referencesData.personal_references.personal_reference_1.phone
            );
            referencesData.personal_references.personal_reference_2.phone = formatPhone(
                referencesData.personal_references.personal_reference_2.phone
            );

            const data = await fetchUpdateFinancialForm(financialForm._id, referencesData);
            setFinancialForm(transformFinancialFormObj({
                ...user,
                financialForms: [data]
            }));
        }
    };

    const handleContinueButton = async () => {
        if (activeSection) {
            const errors = _.get(form.errors, activeSection);
            if (!errors) {
                if (activeSection === ValidationIdentityFormEnum.REFERENCES_STEP) {
                    await updatePersonalReferences();
                    if (form.isValid) {
                        setCompleted(true);
                    }
                }

                let currentIndex = 0;
                documentSection.forEach((e, index) => {
                    if (e.step === activeSection) {
                        currentIndex = index;
                    }
                });
                currentIndex = currentIndex + 1 < documentSection.length ? currentIndex : -1;
                handleActiveSection(documentSection[currentIndex + 1].step as StepType);
            } else {
                form.setTouched({
                    [activeSection]: errors
                }, true);
            }
        }
    };

    return <div className="dso_container" style={{ minHeight: "75vh" }}>
        <div className="m_y_lg_mobile m_y_xxxl p_y_xxl_mobile display_flex_desktop">
            {(!openDetail || matchesMD) && <div
                className={`w_100_per_mobile ${matchesXS && !matchesMD ? " w_300_px m_x_auto" : ""}${matchesMD ? " w_400_px" : ""}`}
            >
                <div className="p_t_xxxl_desktop">
                    <Typography className="m_y_none m_b_md text_center_mobile" weight="400" scale="heading4">
                        Bienvenid@ <strong>{user.name}</strong>
                    </Typography>
                    <Typography className="m_y_none text_center_mobile" weight="400" scale="medium">
                        Solo completa los documentos que faltan y ¡listo!
                    </Typography>
                </div>
                <div className="m_y_lg">
                    {documentSection.map((item, index) => {
                        return <StepperItem
                            key={index}
                            props={item}
                            position={{
                                index,
                                lastPosition: index === documentSection.length - 1,
                                action: (value: StepType) => handleActiveSection(value)
                            }}
                        />;
                    })}
                </div>
            </div>}
            {openDetail && <div className="w_100_per_mobile flex_grow_1_desktop">
                <div className="shadow_hard_desktop br_xs_desktop m_l_sm_desktop p_x_xl_desktop p_y_xxxl_desktop">
                    {!matchesMD && <div>
                        <Typography className="m_y_none m_b_md text_center" weight="400" scale="heading4">
                            Bienvenid@ <strong>{user.name}</strong>
                        </Typography>
                    </div>}
                    <FormikProvider value={form}>
                        {stepBody}
                        <div className="w_100_per_mobile w_300_px_desktop m_b_md m_x_auto">
                            <Button className="w_100_per" scale="small" onClick={handleContinueButton}>
                                <Typography weight="600" scale="medium" scaleMobile="medium">
                                    {(form.isValid && activeSection === ValidationIdentityFormEnum.REFERENCES_STEP) ? "Terminar" : "Continuar"}
                                </Typography>
                            </Button>
                        </div>
                        {!matchesMD && <Button className="w_100_per p_y_none" scale="small" variant="ghost" onClick={handleBackButton}>
                            <Typography weight="600" scale="medium" scaleMobile="medium" textColor="neutral_700">
                                Volver
                            </Typography>
                        </Button>}
                    </FormikProvider>
                </div>
            </div>}
        </div>
        <Modal open={completed} setOpen={setCompleted} className="">
            <Typography scale="large" weight="400" className="m_b_sm text_center">
                ¡Felicidades! Has completado la documentación necesaria
            </Typography>
            <Typography scale="small" weight="400" className="m_b_xl text_center">
                Ahora puedes seguir el paso a paso de tu solicitud al continuar
            </Typography>
            <Link
                to="/mi-cuenta/solicitud"
                className=""
            >
                <Button
                    variant="principal"
                    scale="small"
                    className="w_100_per"
                >
                    Continuar
                </Button>
            </Link>
        </Modal>
    </div>;
};
